

export class Config {

    
    static production()
    {
        return {
            api : 'https://api.etousoft.com/public/api/',
            login : 'https://accounts.etousoft.com/',
            register : 'https://accounts.etousoft.com/sa',
            base : 'https://accounts.etousoft.com',
            home : 'https://etousoft.com',
            captcha : {
                key : '6Lcj6ZgaAAAAANAR49lbeIqAuZIKpTpAvSOvrrbo',
                secret : '6Lcj6ZgaAAAAACBuTFNBw1pMCHqA1bn1U6bAFHyn'
            },
            paypal : {
                production : true,
                client_id : 'AdXoxWhbqwEzPmqsns5goUFSXlMflby4KjTQP-Eg8TV8qrJCF_gE1-XgNs8Novqczve0B-W-CMt3ejfB',
            },
        }
    }

    static staging()
    {
        return  {
            api : 'https://staging-api.etousoft.com/public/api/',
            login : 'https://accounts-staging.etousoft.com/',
            register : 'https://accounts-staging.etousoft.com/sa',
            base : 'https://accounts-staging.etousoft.com',
            home : 'https://staging.etousoft.com',
            captcha : {
                key : '6Lcj6ZgaAAAAANAR49lbeIqAuZIKpTpAvSOvrrbo',
                secret : '6Lcj6ZgaAAAAACBuTFNBw1pMCHqA1bn1U6bAFHyn'
            },
            paypal : {
                production : false,
                client_id : 'AYsh9XF0ticW7hN05usPppSinShrSq6WQ0ioiozgNneIrkWj18ogXXTc37Ibst4i2nhVzvbW3vYNCYvT'
            },
        }
    } 

    static local()
    {
        return {
            api : 'http://127.0.0.1:8000/public/api/',
            login : 'https://accounts-staging.etousoft.com/',
            register : 'https://accounts-staging.etousoft.com/sa/',
            base : 'https://accounts-staging.etousoft.com',
            home : 'http://127.0.0.1:8080/',
            captcha : {
                key : '6LdS0JQaAAAAAKe0Sw0rKZ93BrlJXF7CsTNXvU1V',
                secret : '6LdS0JQaAAAAAGyFbnCs8PlmhVdIKGRhdLwk3g9F'
            },
            paypal : {
                production : false,
                client_id : 'AYsh9XF0ticW7hN05usPppSinShrSq6WQ0ioiozgNneIrkWj18ogXXTc37Ibst4i2nhVzvbW3vYNCYvT',
            }
            
        }
    } 


    static getConfig(setting)
    {
     
        if(setting == 'local')
        {
            return this.local()
        }

        if(setting == 'staging')
        {
            return this.staging()
        }

        if(setting == 'production')
        {
            return this.production()
        }

    }

}


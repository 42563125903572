import { Storage } from './Storage'
import axios from 'axios'


axios.interceptors.request.use(function(config) {
    // Do something before request is sent
    return config;
}, function(error) {

    return Promise.reject(error);
});


axios.interceptors.response.use(function(response) {

    return response;
}, function(error) {
    User.checkExpiredToken(error)
    return Promise.reject(error);
});

export class User {

    static SuccessLogin() {
        const token = localStorage.getItem("token")
        if (token) {
            return true
        }
    }

    static checkExpiredToken(error) {

        const status = error.response.status
        const err = error.response.data
        if (status && status == 401) {
            // this.Logout()
            // window.location.reload()
      }
      
      console.log(err)
    }

    static Logout() {
        axios.defaults.headers.common['Authorization'] = null
        Storage.removeToken()
    }

    static receiveToken() {
        Storage.getToken()
    }

    static getToken() {
        return Storage.getToken()
    }

}
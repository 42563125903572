<template>
  <v-app id="app">

    <Navigation v-if="this.$user.SuccessLogin()" @refresh="refresh" @me="updateMe" v-bind:nav="nav"></Navigation>
    <LandingPageNavbar v-else> </LandingPageNavbar>
    <v-main>
      <router-view :key="key" v-bind:myInfo="me" @refresh="updateNav" ></router-view>
    </v-main>
    <Footer v-bind:myInfo="me" />
    <v-fab-transition>
        <v-btn  id="gototopbtn" elevation="3" large icon fixed bottom right color="#0A009B" @click="goToTop" class="hidden-md-and-up bg-white" v-show="gototopshow" >
          <v-icon>   mdi-arrow-up </v-icon>
        </v-btn>
      </v-fab-transition>
   
  </v-app>
</template>
<script>
import Navigation from './components/AccountDashboard/usersAccountNavbar'
import Footer from './components/LandingPage/footer'
import LandingPageNavbar from './components/LandingPage/navbar'
export default {
  name: 'App',
components: { 
  'Navigation': Navigation ,
  'Footer' : Footer,
  'LandingPageNavbar': LandingPageNavbar
},
  data: () => ({
    render: true,
    key : 1,
    me: null,
    nav : 1,
    overlay : true,
    message : 'Loading data..',
    gototopshow : false,
    option : 'fade-out',
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(){
      if(document.documentElement.scrollTop > 100)
      {
       
        this.gototopshow = true
      }else{
        this.gototopshow = false
      }
    },
    checkScroll()
    {
      this.windowSize = window.innerWidth
    },
    goToTop()
    {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    updateNav()
    {
      this.nav+= 1
    },
    refresh()
    {
      this.key += 1
    },
    updateMe(data)
    {
      this.me = data
      this.overlay = false
    }
  }
};
</script>
<style> 
html {
  scroll-behavior: smooth;
}
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button:active, button:focus{
  outline: none!important;
}
.t-primary{
  color : #0A009B!important;
}
.t-secondary{
  color: #00919B!important;
}
a:hover{
  text-decoration: none;
}
.header{
  z-index: 1;
}
#gototopbtn{
  z-index: 3;
}
.popular{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -13px;
        background: #ffc400;
        right: 10px;
        width: 100px;
        font-weight: bold;
        height: 25px;
        font-size: 12px;
        border-radius: 6px;
    }
</style>

<template>
  <div>
    <v-overlay v-model="over.show" opacity="1" color="white" class="text-center" :z-index="3" >
      <v-progress-circular
        indeterminate
        color="#00919B"
        size="64"
      >
     
      </v-progress-circular>
       <p class="text-center black--text mt-2 text-center">{{ over.message }} </p>
    </v-overlay>

    <v-app-bar app color="#00919B" elevate-on-scroll class="header"  >
      
      <v-container class="hidden-sm-and-down">
        <div class="d-flex align-center justify-content-between">
          <div class="d-flex align-center">
            <router-link to="/account"
            aria-label="Etousoft account links"
            class="black--text nav-items font-weight-bold text-uppercase " >
              Etousoft
            </router-link >
            <div class="d-none d-md-flex d-lg-none align-center justify-start">
            <v-btn
              text
              small
              aria-label="Your Info" 
              to="/account/yourinfo"
              class="ml-4 black--text py-2 px-2 sentence caption">
              Your Info
              </v-btn >
              <v-btn
                aria-label="order historys" 
                text
                small
                v-if="status.admin"
                to="/account/orderhistory"
                class="black--text py-2 px-2 sentence caption" >
                Payment History</v-btn >
              <v-btn
                text
                small
                aria-label="wallet and earnings" 
                to="/account/affiliate/earnings"
                class="black--text py-2 px-2 sentence caption" >
                Wallet and Earnings</v-btn >
              <v-btn
                text
                small
                aria-label="service and subscription" 
                to="/account/servicessubscription"
                class="black--text py-2 px-2 sentence caption" >
                Service and Subscription</v-btn >
              <v-btn
                text
                small
                aria-label="companies" 
                to="/account/mycompanies"
                class="black--text py-2 px-2 sentence caption" >
                My Companies
              </v-btn >
            </div>
            <div class=".d-none  d-md-none d-lg-flex">
              <v-btn
              text
              small
              aria-label="your info" 
              to="/account/yourinfo"
              class="ml-4 black--text btn-lg caption">
              Your Info
              </v-btn >
              <v-btn
                text
                small
                aria-label="Payment history" 
                v-if="status.admin"
                to="/account/orderhistory"
                class="black--text btn-lg caption" >
                Payment History</v-btn >
              <v-btn
                text
                small
                aria-label="wallet and earnings" 
                to="/account/affiliate/earnings"
                class="black--text btn-lg caption" >
                Wallet and Earnings</v-btn >
              <v-btn
                text
                small
                aria-label="service and subscription" 
                to="/account/servicessubscription"
                class="black--text btn-lg caption" >
                Service and Subscription</v-btn >
              <v-btn
                text
                small
                aria-label="my companies" 
                to="/account/mycompanies"
                class="black--text btn-lg caption" >
                My Companies
              </v-btn >
            </div>
            
          </div>
          <div class="d-flex align-center">
            <v-menu bottom min-width="200px" offset-y  transition="slide-y-transition" class="ml-auto">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text aria-label="users"   class="black--text caption btn-lg hidden-sm-and-down"  :loading="loading" >
                  <v-icon size="20" class="mr-2">mdi-office-building</v-icon>
                  {{ user ? user.default_user_company.company.name : 'Loading...'}}
                
                </v-btn>
              </template>
              <v-card max-height="500" tile >
              <v-list class="pt-0" v-if="user" dense>
                <v-list-item class="border-bottom" >
                  <v-list-item-icon> <v-icon color="#00919B">mdi-account-tie-outline</v-icon>   </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-if="user">{{  user.default_user_company.role | role }}</v-list-item-title>
                    <v-list-item-subtitle>Role</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-subheader>{{ status.admin ? 'Companies' : 'Affiliated Companies' }}</v-subheader>
                <v-list-item-group 
                v-model="model"
                color="#01577A"
                
                @change="changeDefaultCompany">
                    
                          <div >
                            <v-list-item active-class="indigo lighten-4" 
                              v-for="company in arrays.companies" :key="company.id" :value="company.id" 
                                :disabled="company.id == model ? true : false " >
                                <v-list-item-content>
                                  <v-list-item-title  :class="company.id == model ? 'caption black--text font-weight-bold' : 'caption dark--text' " >
                                      {{company.company.name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                   {{ company.role | role }} 
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                          </div>
                          <v-list-item class="py-4 caption text-center" v-if="arrays.companies.length <= 0">
                            <v-list-item-content class="text-center">
                              <v-list-item-title class=" text-center">
                                <v-overlay
                                    :absolute="true"
                                    :value="true"
                                    :opacity="0.1"

                                  >
                                  <v-progress-circular
                                    indeterminate
                                    size="36"
                                    color="#01577A"
                                  ></v-progress-circular>
                                </v-overlay>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
            <UserAccount v-bind:user="user"  />
          </div>
        </div>
      </v-container>
      <v-container class="hidden-md-and-up p-0">
        <div class="d-flex align-center">
          <v-icon
          aria-label="drawer" 
          class="hidden-md-and-up mr-4"
            @click="drawer = !drawer"
          > mdi-menu </v-icon>
          <router-link to="/account"
          aria-label="Etousoft account links"
          class="black--text nav-items font-weight-bold text-uppercase " >
            Etousoft
          </router-link >
        </div>
      </v-container>
      
   </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      class="hidden-md-and-up p-0"
      color="#00919B" >

        <UserAccount class="hidden-md-and-up mb-4" v-bind:user="user" />
      
        <v-menu bottom min-width="200px" offset-y class="" transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text aria-label="users"  class="black--text caption btn-sm" large tile block>
              <v-icon size="20" class="mr-2">mdi-office-building</v-icon>
              {{ user ? user.default_user_company.company.name : 'Loading...' }}
            
                <v-icon size="18" class="ml-4">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        <v-card max-height="500" tile>
          <v-list  dense >
            <v-list-item class="border-bottom" >
              <v-list-item-icon> <v-icon color="#0A009B">mdi-account-tie-outline</v-icon>   </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="user">{{ user.default_user_company.role | role }}</v-list-item-title>
                <v-list-item-subtitle>Role</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>{{ status.admin ? 'Companies' : 'Affiliated Companies' }}</v-subheader>
            <v-list-item-group 
            v-model="model"
            color="#01577A"
            @change="changeDefaultCompany">
             
                      <div>
                        <v-list-item active-class="indigo lighten-4"
                          v-for="company in arrays.companies" :key="company.id" :value="company.id" 
                            :disabled="company.id == model ? true : false "
                          >
                            <v-list-item-content>
                              <v-list-item-title :class="company.id == model ? 'caption black--text font-weight-bold' : 'caption dark--text' ">
                                <span>
                                  {{company.company.name}}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </div>
                      <v-list-item class="py-4 caption text-center" v-if="arrays.companies.length <= 0 ">
                        <v-list-item-content class="text-center">
                          <v-list-item-title class=" text-center">
                            <v-overlay
                                :absolute="true"
                                :value="true"
                                :opacity="0.1"

                              >
                              <v-progress-circular
                                indeterminate
                                size="36"
                                color="#01577A"
                              ></v-progress-circular>
                            </v-overlay>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-list dense>
        <v-subheader>Navigation</v-subheader>
        <v-list-item-group mandatory>
            <v-list-item  exact to="/account/yourinfo"  aria-label="your info">
            <v-list-item-icon>
              <v-icon size="18" color="black" >mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              Your Info
            </v-list-item-content>
          </v-list-item>
          <v-list-item exact to="/dashboard" >
            <v-list-item-icon>
              <v-icon size="18" color="black" >mdi-apps</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              Dashboard
            </v-list-item-content>
          </v-list-item>
          <v-list-item exact to="/account" aria-label="manage">
            <v-list-item-icon>
              <v-icon size="18" color="black" > mdi-account-cog-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              Manage Account
            </v-list-item-content>
          </v-list-item>

          <v-list-item exact to="/account/orderhistory" v-if="status.admin" aria-label="order history">
              <v-list-item-icon>
                <v-icon size="18" color="black" >mdi-history</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-caption">
                Payment History
              </v-list-item-content>
          </v-list-item>
          <v-list-item exact to="/account/affiliate/earnings" aria-label="earnings">
            <v-list-item-icon>
              <v-icon size="18" color="black" >mdi-wallet-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              Wallet And Earnings
            </v-list-item-content>
          </v-list-item>
          <v-list-item exact to="/account/servicessubscription" aria-label="service and subscription">
            <v-list-item-icon>
              <v-icon size="18" color="black" >mdi-room-service-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              Service and Subscription
            </v-list-item-content>
          </v-list-item>
          <v-list-item exact to="/account/mycompanies"  aria-label="my companies">
            <v-list-item-icon>
              <v-icon size="18" color="black" >mdi-office-building-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              My Companies
            </v-list-item-content>
          </v-list-item>
          <v-list-item exact  to="/logout" aria-label="logout"> 
            <v-list-item-icon>
              <v-icon size="18" color="black" >mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-caption">
              Sign out
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        
      </v-list>
    </v-navigation-drawer>
  </div>
</template>


<script>
import UserAccount from "@/components/AccountDashboard/userAccount";

export default {
  components: {
    UserAccount,
  },
  props: {
    nav : Number
  },
  data() {
    return {
      drawer: null,
      model: null,
      arrays : {
        companies : [],
        user_company : [],
        new : [],
        products : [],
      },
      status: {
        admin : false,
        current : 'Loading...',
        id : null,
        current_user : {
          name : null,
          active : null,
          new : null,
          updating : false,
        },
        current_company : {
          name : null,
          active : null,
          new : null,
          updating : false,
        },
        multiple_page: false,
        fetching : false
      },
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      pagination : {
        meta : null
      },
      forms: {
        old : {},
        new : {}
      },

      user: null,
      loading: true,
      over : {
        show : true,
        message : 'Loading user information',
      },
      countDown : 0,
    };
  },
  created() {
    this.me()
  },
  watch : {
    nav : function()
    {
      this.me()
    },
    countDown: {
      handler(value) {

          if (value > 0) {
              setTimeout(() => {
                  this.countDown--;
                  if(this.countDown <= 0)
                  {
                    this.over.show = false
                    this.timer = false
                    window.scrollTo({top: 0, behavior: 'smooth'})
                  }
              }, 1000);
          }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
    
  },
  methods:{
    go(url)
    {
      this.$router.push({path : url})
    },
    getCurrency(data)
    {
      this.$options.filters.setTimezone(data.default_user_company.timezone.time_difference)
      return data
    },
    me()
    {
      if(this.status.fetching) return
      this.over.message = "Loading User Information"
      this.status.fetching = true
      this.user = null
      this.axios.get('users/me')
      .then(({data}) => {
        this.user = this.getCurrency(data.user)
        this.$options.filters.setBaseURL(data.base_url)
        this.$emit('me', this.user)
        this.over.message = "Load complete"
        this.countDown = 2
        if(this.user.default_user_company.role == 'SA')
        {
          this.status.admin = true
          
        }else {
          this.status.admin = false
        }
        if(this.$route.meta.access)
          {
            if(!this.status.admin && this.$route.meta.access.admin)
            {
              this.$router.push({path : '/dashboard'})
            }
          }
        this.getUsersCompanies()
    
      })
      .catch( (error) => {
        this.over.message = "Redirecting"
        if(!error.response)
        {
          console.log(error)
          // window.location.reload()
        }else{
          let status = error.response.status
          if(status == 500)
          {
            this.$user.Logout()
            window.location.reload()
          }
        }
      })
      .finally( () => {
        this.status.fetching = false
      })
    },
    getUsersCompanies(){
      let url = 'getusercombyuserid/'+this.user.id
      this.axios.get(url)
      .then( ({data}) => {
        this.arrays.companies = data.companies
        this.model = this.user.default_user_company.id
      })
      .finally( () => {
        this.loading = false
      })
    },
    changeDefaultCompany(){
      this.loading = true
      this.user.current_user_company_id = this.model
      this.axios.put("/userslist/" + this.user.id, this.user)
      .catch( () => {
        console.log('error!')
      })
      .finally( () => {
        this.loading = false
        this.me()
      })
    },
  }
};
</script>
<style scoped>
.nav-items {
  text-decoration: none;
  border: none;
  text-transform: none;

}
.btn-lg {
  text-transform: none;
  background-color: transparent;
}
.sentence{
  text-transform: none;
  background-color: transparent;
}
.btn-sm {
  text-transform: none;
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

}
.v-list .v-list-item--active .v-list-item__title span{
  color: black!important;
}
.header{
  z-index: 2!important;
}

</style>